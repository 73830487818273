import React, { useEffect, useRef, useState } from "react";
import Table from "../../components/Table/Table";
import { itemXrefHeader } from "../../components/Table/table-header/tableHeader";
import * as constants from "../../constants/constants";
import { userManagement } from "../../constants/table-columns/tableColumns";
import { deleteUser, getUserList } from "../../services/userManagement";
import { Toast } from "primereact/toast";
import Spinner from "../../components/spinner/spinner";
import UserDialog from "./UserDialog";
import { appInsights } from "../../AppInsights";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import styles from "./User.module.scss";

const UserList = ({ onFetchUserListAvailable }) => {
  appInsights.trackPageView({ name: "User Management Screen visited" });
  const toast = useRef(null);
  const [showSpinner, setShowSpinner] = useState(true);
  const [userList, setUserList] = useState([]);
  const [userListCopy, setUserListCopy] = useState([]);
  const [selectedUserEmail, setSelectedUserEmail] = useState(null);
  const [searchedItem, setSearchedItem] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);

  useEffect(() => {
    fetchUserList();
  }, []);
  const fetchUserList = async () => {
    try {
      setShowSpinner(true);
      let result = "";
      result = await getUserList();
      if (result) {
        setShowSpinner(false);
        setUserList(result);
        setUserListCopy(result);
      }
    } catch (error) {
      setShowSpinner(false);
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: error?.message,
        life: 4000
      });
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Error while fetching User List."
        },
        measurements: {
          severityLevel: 2
        }
      });
    }
  };
  useEffect(() => {
    if (onFetchUserListAvailable) {
      onFetchUserListAvailable(fetchUserList);
    }
    fetchUserList();
  }, []);
  const editItem = (rowData) => {
    setSelectedUser(rowData);
    setShowDialog(true);
  };
  const deleteItem = (email) => {
    setSelectedUserEmail(email);
    setConfirmDialog(true);
  };
const handleHideDialog = ()=>{
  setShowDialog(false);
}
  const accept = async () => {
    try {
      setShowSpinner(true);
      const result = await deleteUser(selectedUserEmail);
      toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: result,
        life: 4000
      });
      setShowSpinner(false);
      const updatedUserList = userList.filter(
        (user) => user?.emailAdrress !== selectedUserEmail
      );
      setUserList(updatedUserList);
      setUserListCopy(updatedUserList);
      setConfirmDialog(false);
    } catch (error) {
      setShowSpinner(false);
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: error?.message,
        life: 4000
      });
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Error while deleting User."
        },
        measurements: {
          severityLevel: 2
        }
      });
    }
  };
  const cancel = () => {
    setConfirmDialog(false);
  };
  const dialogFooter = (
    <div>
      <Button onClick={cancel} autoFocus>
        Cancel
      </Button>
      <Button
        onClick={accept}
        autoFocus
        style={{
          backgroundColor: "#FF3B30",
          color: "white",
          borderRadius: "4px",
          border: "1px solid #FF3B30"
        }}
      >
        Delete User
      </Button>
    </div>
  );
  const onSearch = (event) => {
    const searchedWord = event.target.value;
    DataFilterLogic(searchedWord);
  };
  const DataFilterLogic = (searchedItem) => {
    try {
      setSearchedItem(searchedItem);
      if (searchedItem.trim() == "") {
        setUserList(userListCopy);
      } else if (searchedItem.trim() != "") {
        const data = userListCopy.filter((item) =>
          Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchedItem.toLowerCase())
          )
        );
        setUserList(data);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Exception while searching UserList data"
        },
        measurements: {
          severityLevel: 2
        }
      });
    }
  };
  const onClear = () => {
    setSearchedItem("");
    setUserList(userListCopy);
  };

  return (
    <div>
      {showSpinner && <Spinner />}
      <Table
        content={userList}
        columnsData={userManagement(editItem, (user) =>
          deleteItem(user?.emailAdrress)
        )}
        rows={constants.defaultRows}
        rowsPerPageOptions={constants.rowsPerPageOptions}
        paginator={constants.paginator}
        scrollable={constants.scrollable}
        emptyMessage={constants.emptyMessage}
        tableStyle={constants.tableStyle}
        currentPageReportTemplate={constants.currentPageReportTemplate}
        paginatorTemplate={constants.paginatorTemplate}
        header={() =>
          itemXrefHeader({ searchedItem, onSearch, onClear, styles })
        }
        isCopyEnable={false}
      ></Table>
      {showDialog && (
        <UserDialog
          visible={showDialog}
          onHide={handleHideDialog}
          data={selectedUser}
          fetchUser={fetchUserList}
        />
      )}
      {confirmDialog && (
        <ConfirmDialog
          className="deleteDialog"
          visible={confirmDialog}
          message="This action will permanently delete the user and all related information. Are you sure you want to proceed?"
          header="Warning : Confirm User Deletion"
          icon="pi pi-exclamation-triangle"
          style={{ width: "50vw", color: "red" }}
          breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          footer={dialogFooter}
        ></ConfirmDialog>
      )}
      <Toast ref={toast} position="center" />
    </div>
  );
};

export default UserList;
