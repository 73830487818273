import Card from "../../components/shared-card/card";
import styles from "../login/login.module.scss";
import TaylorFarms from "../../assets/TaylorFarms.png";

function NoAccess() {
  const heading = "MOJO";
  const supportMail ="support@taylorfarms.com"


  return (
    <div className={styles.loginContainer}>
        <div style={{width:'90%'}}>
        <Card >
        <div className={styles.loginSubContainer}>
        <div className="grid" style={{alignItems:'center'}}>
          <div className={`col-12 sm:col-6  text-center md:text-right`}>
            <img
              src={TaylorFarms}
              alt="Taylor Farms"
              className={styles.brandLogo}
            />
          </div>
          <div className="col-12 sm:col-6 text-center md:text-left">
            <h2 className={`${styles.brandName}`}>{heading}</h2>
            {/* <img
                src={Mojo}
                alt="MOJO"
                className={styles.brandLogo}
              /> */}
          </div>
        </div>
        <div className={`grid ${styles.loginContainerBox}`}>
          <span className={`col-12 ${styles.subHeading} ${styles.title}`}>Apologies, it appears you don't have permission to access the MOJO app. Please reachout to <a href={`mailto:${supportMail}`}  className={styles.confirmationMail}>{supportMail}</a> for future assistance.</span>
        </div>
        </div>
      </Card>
        </div>
    </div>
  );
}

export default NoAccess;
