import React, { useState } from 'react'
import UserHeading from './UserHeading'
import UserList from './UserList'

const User = () => {
  const [fetchUserList, setFetchUserList] = useState(null);

  const handleFetchUserList = (fetchUser) => {
    setFetchUserList(() => fetchUser);
  };

  return (
    <div>
      <UserHeading fetchUserList={fetchUserList}/>
      <UserList onFetchUserListAvailable={handleFetchUserList} />
    </div>
  )
}

export default User
