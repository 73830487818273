import { useEffect, useState, useRef } from "react";
import globalStyles from "./functionSetup.module.scss";
import Spinner from "../../components/spinner/spinner";
import Table from "../../components/Table/Table";
import { itemXrefColumns } from "../../constants/table-columns/tableColumns"
import { itemXrefHeader } from "../../components/Table/table-header/tableHeader";
import * as constants from "../../constants/constants";
import {getXrefData} from "../../services/crossref";
import styles from "./xref.module.scss";
import { appInsights } from '../../AppInsights';
import { Toast } from "primereact/toast";

const ItemXref = () =>{
    appInsights.trackPageView({ name: 'ItemXref component visited' });
    const toast = useRef(null);
    const [showSpinner, setShowSpinner] = useState(true);
    const [itemXrefData, setItemXrefData] = useState([]);
    const [itemXrefDataCopy, setItemXrefDataCopy] = useState([])
    const [searchedItem, setSearchedItem] = useState("");

    useEffect(()=>{
        fetchItemXrefdata()
    },[])

    const fetchItemXrefdata = async () =>{
        try{
            setShowSpinner(true)
            let result = "";
            result = await getXrefData();
            if(result){
                setShowSpinner(false);
                setItemXrefData(result);
                setItemXrefDataCopy(result);
            }
        }
        catch(error){
            setShowSpinner(false)
            appInsights.trackException({
                exception: error,
                properties: {
                  additionalInfo: "Exception while getting ItemXref data",
                },
                measurements: {
                  severityLevel: 2 
                }
              });
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: error.message,
                life: 4000
              });
        }
    }

    const onSearch = (event) => {
        const searchedWord = event.target.value;
        DataFilterLogic(searchedWord);
    };

    const DataFilterLogic = (searchedItem) =>{
        try{
            setSearchedItem(searchedItem);
            if (searchedItem.trim() == "") {
                setItemXrefData(itemXrefDataCopy);
              } else if (searchedItem.trim() != "") {
                const data = itemXrefDataCopy.filter((item) =>
                  Object.values(item).some(
                      (value) =>
                        typeof value === 'string' &&
                        value.toLowerCase().includes(searchedItem.toLowerCase())
                    )
                );
                setItemXrefData(data);
              } 
        }
        catch(error){
            appInsights.trackException({
                exception: error,
                properties: {
                  additionalInfo: "Exception while searching ItemXref data",
                },
                measurements: {
                  severityLevel: 2 
                }
              });
        }
    }

    const onClear = () =>{
        setSearchedItem("");
        setItemXrefData(itemXrefDataCopy);
    }

      
    return (
      <>
        {showSpinner && <Spinner />}
        <div className="grid">
          <div className={`col-12 ${globalStyles.headerContainer}`}>
            <h3 className={globalStyles.heading}>Overview</h3>
          </div>
        </div>
        <Table
          content={itemXrefData}
          columnsData={itemXrefColumns}
          rows={constants.defaultRows}
          rowsPerPageOptions={constants.rowsPerPageOptions}
          paginator={constants.paginator}
          scrollable={constants.scrollable}
          emptyMessage={constants.emptyMessage}
          tableStyle={constants.tableStyle}
          currentPageReportTemplate={constants.currentPageReportTemplate}
          paginatorTemplate={constants.paginatorTemplate}
          header={()=>itemXrefHeader({searchedItem,onSearch,onClear,styles})}
          isCopyEnable={false}
        ></Table>
         <Toast ref={toast} position="center" />
      </>
    );
}

export default ItemXref;