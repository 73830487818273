import { useState, useRef, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { appInsights } from "../../AppInsights";
import { Formik, Form } from "formik";
import * as YUP from "yup";
import { useDispatch, useSelector } from "react-redux";
import styles from "./EntitySearch.module.scss";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { sortData } from "../../constants/dataSort";
import Table from "../../components/Table/Table";
import { itemXrefColumns } from "../../constants/table-columns/tableColumns";
import { itemXrefHeader } from "../../components/Table/table-header/tableHeader";
import { shipToXrefColumns } from "../../constants/table-columns/tableColumns";
import * as constants from "../../constants/constants";
import {
  customerItemGroupList,
  itemXrefDataList,
  shipToXrefDataList
} from "../../util/mockData";

const initialValue = {
  region: null,
  customerItemGroup: null,
  entity: null,
  id: ""
};

const EntitySearch = () => {
  appInsights.trackPageView({ name: "Entity search component visited" });
  const formRef = useRef(null);
  const [searchedItem, setSearchedItem] = useState("");
  const [itemXrefData, setItemXrefData] = useState([]);
  const [id, setId] = useState("");
  const [shipToXrefData, setShipToXrefData] = useState([]);
  const dispatch = useDispatch();
  const [showItemXrefTable, setShowItemXrefTable] = useState(true);
  const [customerItemGroupListFilterred, setCustomerItemGroupListFilterred] =
    useState([]);

  const entityList = [
    { label: "ItemXref", id: 1 },
    { label: "ShipToXref", id: 2 }
  ];
  const { getRegions } = bindActionCreators(actionCreators, dispatch);
  const regionList = useSelector((state) => {
    const data = state.orderReducer.regions;
    const sortedData = sortData("regionNameAbbr", data);
    return sortedData;
  });

  useEffect(() => {
    getRegions();
  }, []);

  const commonValidation = YUP.mixed().nullable();
  const validationSchema = YUP.object().shape({
    region: commonValidation.required("Region is required"),
    customerItemGroup: YUP.object()
      .nullable()
      .required("Customer Item Group is required"),
    entity: YUP.object().nullable().required("Entity is required"),
    id: YUP.string().nullable().required(" Entity Id is required")
  });

  const onSearch = (event) => {
    const searchedWord = event.target.value;
    DataFilterLogic(searchedWord);
  };

  const DataFilterLogic = (searchedItem) => {
    try {
      setSearchedItem(searchedItem);
      if (
        searchedItem.trim() == "" &&
        formRef?.current?.values?.entity?.id == 1
      ) {
        setShipToXrefData([]);
        setItemXrefData(itemXrefDataList);
      } else if (
        searchedItem.trim() == "" &&
        formRef?.current?.values?.entity?.id == 2
      ) {
        setShipToXrefData(shipToXrefDataList);
        setItemXrefData([]);
      } else if (
        searchedItem.trim() != "" &&
        formRef?.current?.values?.entity?.id == 1
      ) {
        const data = itemXrefDataList.filter((item) =>
          Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchedItem.toLowerCase())
          )
        );
        setItemXrefData(data);
      } else if (
        searchedItem.trim() != "" &&
        formRef?.current?.values?.entity?.id == 2
      ) {
        const data = shipToXrefDataList.filter((item) =>
          Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchedItem.toLowerCase())
          )
        );
        setShipToXrefData(data);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Exception while searching shiptToXref data"
        },
        measurements: {
          severityLevel: 2
        }
      });
    }
  };

  const onClear = () => {
    setSearchedItem("");
    setItemXrefData([]);
    setShipToXrefData([]);
  };

  const handleEntityChange = (event) => {
    if (event?.target?.value?.id == 1) {
      setShowItemXrefTable(true);
    } else {
      setShowItemXrefTable(false);
    }
    setItemXrefData([]);
    setShipToXrefData([]);
  };

  const handleRegionChange = (event) => {
    const regionId = event?.target?.value?.regionId;
    const data = customerItemGroupList?.filter(
      (item) => item?.regionId == regionId
    );
    setCustomerItemGroupListFilterred(data);
  };

  const handleIdChange = (event) => {
    const { value } = event.target;
    setId(value);
    formRef?.current?.setFieldValue("id", value);
  };

  const fetchData = (formbody) => {
    console.log(formbody?.entity?.id);
    if (formbody?.entity?.id == 1) {
      setItemXrefData(itemXrefDataList);
    } else {
      setShipToXrefData(shipToXrefDataList);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          fetchData(values);
        }}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit} ref={formRef}>
            <div className="grid">
              <div className="col-12 md:col-3">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="region">
                    <span className={styles.formLabel}>Region</span>
                  </label>
                </div>
                <Dropdown
                  id="region"
                  filter
                  placeholder="Select a Region"
                  value={values?.region}
                  options={regionList}
                  optionLabel="regionNameAbbr"
                  onChange={(e) => {
                    handleChange(e);
                    handleRegionChange(e);
                  }}
                  className={`${styles.dropdown} ${styles.formControl}`}
                />
                <div>
                  {errors.region && touched.region && (
                    <span className={styles?.errorMsg}>{errors.region}</span>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-3">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="customerItemGroup">
                    <span className={styles.formLabel}>
                      Customer Item Group
                    </span>
                  </label>
                </div>
                <Dropdown
                  id="customerItemGroup"
                  filter
                  placeholder="Select a Customer Item Group"
                  value={values?.customerItemGroup}
                  options={customerItemGroupListFilterred}
                  optionLabel="customerName"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  className={`${styles.dropdown} ${styles.formControl}`}
                />
                <div>
                  {errors.customerItemGroup && touched.customerItemGroup && (
                    <span className={styles?.errorMsg}>
                      {errors.customerItemGroup}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-3">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="entity">
                    <span className={styles.formLabel}>Entity</span>
                  </label>
                </div>
                <Dropdown
                  id="entity"
                  filter
                  placeholder="Select a Entity"
                  value={values?.entity}
                  options={entityList}
                  optionLabel="label"
                  onChange={(e) => {
                    handleChange(e);
                    handleEntityChange(e);
                  }}
                  className={`${styles.dropdown} ${styles.formControl}`}
                />
                <div>
                  {errors.entity && touched.entity && (
                    <span className={styles?.errorMsg}>{errors.entity}</span>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-3">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="id">
                    <span className={styles.formLabel}>Entity Id</span>
                  </label>
                </div>
                <span className="p-input-icon-left">
                  <InputText
                    id="id"
                    value={id}
                    onChange={handleIdChange}
                    onBlur={handleBlur}
                    className={`${styles.inputField} ${styles.formControl}`}
                  />
                  <div>
                    {errors.id && touched.id && (
                      <span className={styles?.errorMsg}>{errors.id}</span>
                    )}
                  </div>
                </span>
              </div>
            </div>
            <div className="grid">
              <div className="col-12 md:col-4 md:col-offset-8 text-right">
                <Button
                  label="Search"
                  type="submit"
                  size="medium"
                  className={styles.primaryBtn}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Table
        content={showItemXrefTable ? itemXrefData : shipToXrefData}
        columnsData={showItemXrefTable ? itemXrefColumns : shipToXrefColumns}
        rows={constants.defaultRows}
        rowsPerPageOptions={constants.rowsPerPageOptions}
        paginator={constants.paginator}
        scrollable={constants.scrollable}
        emptyMessage={constants.emptyMessage}
        tableStyle={constants.tableStyle}
        currentPageReportTemplate={constants.currentPageReportTemplate}
        paginatorTemplate={constants.paginatorTemplate}
        header={() =>
          itemXrefHeader({ searchedItem, onSearch, onClear, styles })
        }
        isCopyEnable={false}
      ></Table>
    </>
  );
};

export default EntitySearch;
