"use client";

import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { NavLink } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { FiPlusCircle } from "react-icons/fi";
import { BiMinusCircle } from "react-icons/bi";
import { HiOutlineHome } from "react-icons/hi2";
import { FaRegFile } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { RxGear } from "react-icons/rx";
import { BiUser } from "react-icons/bi";
import { HiOutlineUsers } from "react-icons/hi2";
import { GoBell } from "react-icons/go";

import TaylorFarms from "../../assets/TaylorFarms.png";
import Mojo from "../../assets/Mojo.png";
import { sidemenu } from "../../util/navdetails";
import SideSubmenu from "../../components/sub-menu";
import FeatureFlagDataContext from "../../store/featureFlags";

import styles from "../side-nav/sideNav.module.scss";

const iconComponents = {
  home: HiOutlineHome,
  Orders: FaRegEdit,
  admin: RxGear,
  user: BiUser,
  customer: HiOutlineUsers,
  bell: GoBell
};

const DynamicIconRenderer = ({ icon }) => {
  const IconComponent = iconComponents[icon];
  return IconComponent ? <IconComponent className={styles?.menuIcon} /> : null;
};

export default function SideNav(props) {
  const brandName = "MOJO";
  const [featureFlags, setFeatureFlags] = useContext(FeatureFlagDataContext);
  const { isMenuExpanded } = props;
  const [menu, setMenu] = useState(
    sidemenu(featureFlags?.enableAutoLog, featureFlags?.enableEDIPurchaseOrder, featureFlags?.enableItemXRef, featureFlags?.enableShipToXref, featureFlags?.enableEntity, featureFlags?.enableUsers)
  );

  useEffect(() => {
    setMenu(
      sidemenu(
        featureFlags?.enableAutoLog,
        featureFlags?.enableEDIPurchaseOrder,
        featureFlags?.enableItemXRef, 
        featureFlags?.enableShipToXref,
        featureFlags?.enableEntity,
        featureFlags?.enableUsers
      )
    );
  }, [featureFlags?.enableAutoLog, featureFlags?.enableEDIPurchaseOrder, featureFlags?.enableItemXRef, featureFlags?.enableShipToXref, featureFlags?.enableEntity,  featureFlags?.enableUsers]);

  const handleAccordionChange = (id) => {
    const updatedMenu = menu?.map((item) => {
      if (item.id === id) {
        return { ...item, isExpanded: !item?.isExpanded };
      }
      return item;
    });
    setMenu(updatedMenu);
  };

  const toggleSideMenu = (menuFlag) => {
    props.toggleMenu(menuFlag);
  };

  const subMenuPopUp = (id, flag) => {
    const updatedMenu = menu?.map((item) => {
      if (item.id === id) {
        return { ...item, showSubMenuFlag: flag };
      } else {
        return { ...item, showSubMenuFlag: false };
      }
    });
    setMenu(updatedMenu);
  };

  return (
    <>
      {isMenuExpanded ? (
        <Grid container className={styles.container}>
          <div className={styles.brandingContainer}>
            <Grid item xs={2}>
              {/* <img
                src={TaylorFarms}
                alt="Taylor Farms"
                className={styles.brandLogo}
              /> */}
              {/* <img
                src={Mojo}
                alt="MOJO"
                className={styles.brandLogo}
              /> */}
            </Grid>
            <Grid item xs={6}>
              {/* <img
                src={TaylorFarms}
                alt="Taylor Farms"
                className={styles.brandLogo}
              /> */}
              <img src={Mojo} alt="MOJO" className={styles.brandLogo} />
            </Grid>
            <Grid item xs={3}>
              {/* <h1 className={styles.brandName}>{brandName}</h1> */}
            </Grid>
            <Grid item xs={1} className={styles.menuToggleContainer}>
              <IconButton
                aria-label="delete"
                size="small"
                className={styles.toggleIcon}
                onClick={() => toggleSideMenu(false)}
              >
                <IoIosArrowBack />
              </IconButton>
            </Grid>
          </div>
          <div className={styles.menuContainerBlock}>
            {menu?.map((menuItem) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={menuItem.id}
                  className={styles.menuContainer}
                >
                  {menuItem?.menuItems.length > 0 && (
                    <Accordion
                      sx={{ boxShadow: 0 }}
                      defaultExpanded
                      expanded={menuItem?.isExpanded}
                      onChange={() => handleAccordionChange(menuItem.id)}
                    >
                      <AccordionSummary
                        expandIcon={
                          menuItem?.isExpanded ? (
                            <BiMinusCircle />
                          ) : (
                            <FiPlusCircle />
                          )
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className={styles.accordionHeading}
                      >
                        <Typography>
                          <span className={styles.menuHeadingBlock}>
                            <DynamicIconRenderer icon={menuItem?.icon} />
                            <span className={styles.menuHeading}>
                              {menuItem?.heading}
                            </span>
                          </span>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        className={styles.submenuContainerBlock}
                      >
                        <SideSubmenu
                          menuItem={menuItem}
                          isMenuExpanded={isMenuExpanded}
                        />
                      </AccordionDetails>
                    </Accordion>
                  )}
                </Grid>
              );
            })}
          </div>
        </Grid>
      ) : (
        <Grid
          container
          spacing={2}
          className={`${styles.container} ${styles.collapsedContainer} ${styles.show}`}
        >
          <Grid item xs={12} container className={styles.brandingContainer}>
            <Grid item xs={12} className={styles.logoContainer}>
              <Grid
                item
                xs={11}
                style={{ paddingTop: "15PX", paddingBottom: "15px" }}
              >
                {/* <img
                  src={TaylorFarms}
                  alt="Taylor Farms"
                  className={styles.brandLogo}
                />
                 <h2
                  className={`${styles.brandName} ${styles.collapsedBrandName}`}
                >
                  {brandName}
                </h2> */}
                <img src={Mojo} alt="MOJO" className={styles.brandLogo} />
              </Grid>
              <Grid item xs={1} className={styles.menuToggleContainer}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  className={styles.toggleIcon}
                  onClick={() => toggleSideMenu(true)}
                >
                  <IoIosArrowForward />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {menu?.map((menuItem) => {
                return (
                  <Grid
                    item
                    xs={12}
                    key={menuItem.id}
                    className={styles.collapsedMenuContainer}
                    onMouseLeave={() => subMenuPopUp(menuItem?.id, false)}
                  >
                     {menuItem?.menuItems.length > 0 &&<NavLink
                      to={menuItem?.path}
                      className={({ isActive }) =>
                        isActive
                          ? ` ${styles.collapsedMenuItems} ${styles.active}  `
                          : styles.collapsedMenuItems
                      }
                      onMouseEnter={() => subMenuPopUp(menuItem?.id, true)}
                    >
                      <DynamicIconRenderer icon={menuItem.icon} />
                    </NavLink>}
                    {menuItem?.menuItems.length > 0 &&
                    menuItem?.showSubMenuFlag ? (
                      <div className={styles.menuPopUp}>
                        <SideSubmenu
                          menuItem={menuItem}
                          isMenuExpanded={isMenuExpanded}
                        />
                      </div>
                    ) : null}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
