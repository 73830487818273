const createOrderSubMenu = [
  {
    submenu: "Create Order",
    id: 21,
    path: "/ofp/orders/create"
  },
  {
    submenu: "Log Summary",
    id: 25,
    path: "/ofp/orders/logsummary"
  },
  {
    submenu: "Automated Orders",
    id: 26,
    path: "/ofp/orders/autoLog"
  },
  {
    submenu: "Switchboard Routing",
    id: 27,
    path: "/ofp/orders/edipurchaseorder"
  }
];

const administrationSubMenu =  [
  {
    submenu: "Function Setup",
    id: 32,
    path: "/ofp/administration/function"
  },
  {
    submenu: "ItemXref",
    id: 33,
    path: "/ofp/administration/itemXref"
  },
  {
    submenu: "ShipToXref",
    id: 34,
    path: "/ofp/administration/shipToxref"
  },
  {
    submenu: "Entity",
    id: 35,
    path: "/ofp/administration/entitySearch"
  }
];

const userManagementSubMenu = [
  {
    submenu: "Users",
    id: 36,
    path: "/ofp/UserManagement/Users"
  }
]

export const sidemenu = (showAutoLog, showEdi850PurchaseOrder, showItemXref, ShowShipToXref, showEntity, showUsers) => {
  let filteredSubMenu = [...createOrderSubMenu];
  let filteredAdministrationSubMenu = [...administrationSubMenu];
  let filteredUserManagementSubMenu = [...userManagementSubMenu];
  if (!showAutoLog) {
    filteredSubMenu = filteredSubMenu.filter((item) => item.id !== 26);
  }

  if (!showEdi850PurchaseOrder) {
    filteredSubMenu = filteredSubMenu.filter((item) => item.id !== 27);
  }
  if(!showItemXref){
    filteredAdministrationSubMenu = filteredAdministrationSubMenu.filter((item) => item.id !== 33);
  }
  if(!ShowShipToXref){
    filteredAdministrationSubMenu = filteredAdministrationSubMenu.filter((item) => item.id !== 34);
  }
  if(!showEntity){
    filteredAdministrationSubMenu = filteredAdministrationSubMenu.filter((item) => item.id !== 35);
  }
  if(!showUsers){
    filteredUserManagementSubMenu = filteredUserManagementSubMenu.filter((item) => item.id !== 36);
  }
  const menu = [
    {
      heading: "Orders",
      id: 2,
      icon: "Orders",
      path: "/ofp/orders",
      routePath: "/ofp/orders",
      isExpanded: true,
      showSubMenuFlag: false,
      menuItems: filteredSubMenu
    },
    {
      heading: "Administration",
      id: 3,
      icon: "admin",
      path: "/ofp/administration/function",
      routePath: "/ofp/administration/function",
      isExpanded: true,
      showSubMenuFlag: false,
      menuItems:filteredAdministrationSubMenu
    },
    {
      heading: "User Management",
      id: 4,
      icon: "admin",
      path: "/ofp/UserManagement",
      routePath: "/ofp/UserManagement",
      isExpanded: true,
      showSubMenuFlag: false,
      menuItems: filteredUserManagementSubMenu
    }
  ];

  return menu;
};
