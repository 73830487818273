import { api } from "../api/api";

export const getUserList = async () => {
  try {
    const response = await api.get("/api/Users");
    return response.data?.result?.result || [];
  } catch (error) {
    if (error?.code?.trim() == "ERR_NETWORK") {
      throw new Error(
        "It looks like we can't reach the server right now. Please try refreshing the page or check after sometime."
      );
    } else {
      throw new Error(error?.response?.data?.title);
    }
  }
};

export const addNewUser = async (formData) => {
  try {
    const response = await api.post("/api/Users", formData, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    return response.data?.result?.result || [];
  } catch (error) {
    if (error?.code?.trim() == "ERR_NETWORK") {
      throw new Error(
        "It looks like we can't reach the server right now. Please try refreshing the page or check after sometime."
      );
    } else {
      throw new Error(error?.response?.data?.title);
    }
  }
};

export const editUserList = async (formData) => {
  try {
    const response = await api.put("/api/Users", formData, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    return response.data?.result?.result || [];
  } catch (error) {
    if (error?.code?.trim() == "ERR_NETWORK") {
      throw new Error(
        "It looks like we can't reach the server right now. Please try refreshing the page or check after sometime."
      );
    } else {
      throw new Error(error?.response?.data?.title);
    }
  }
};

export const deleteUser = async (email) => {
  try {
    const response = await api.delete("/api/Users", {
      headers: {
        "Content-Type": "application/json"
      },
      data:  email 
    });
    return response.data?.result?.result || [];
  } catch (error) {
    if (error?.code?.trim() == "ERR_NETWORK") {
      throw new Error(
        "It looks like we can't reach the server right now. Please try refreshing the page or check after sometime."
      );
    } else {
      throw new Error(error?.response?.data?.title);
    }
  }
};
