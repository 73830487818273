import { useEffect, useRef, useState } from "react";
import {
  getDuplicateEdiPurchaseOrders,
  getEdiPurchaseOrders,
  getFailedOrdersExist
} from "../../services/ediPurchaseOrder";
import Spinner from "../../components/spinner/spinner";
import { Toast } from "primereact/toast";
import Table from "../../components/Table/ediRowExpansionTable";
import * as constants from "../../constants/constants";
import {
  ediDuplicateColumns,
  ediFilePurchaseOrderColumns
} from "../../constants/table-columns/tableColumns";
import { rowExpansionEdiLineItemsColumns } from "../../constants/table-columns/tableColumns";
import styles from "./ediPurchaseOrder.module.scss";
import { Form, Formik } from "formik";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import {
  showStatusOptions,
  showStatusKeyValueOptions
} from "../../constants/constants";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { format, parseISO } from "date-fns";
import { Tag } from "primereact/tag";

const initialValue = {
  purchaseOrderNumber: null,
  vendorSequenceNumber: null,
  showStatus: showStatusOptions.Pending,
  deliveryStartDate: null,
  deliveryEndDate: null
};

const EdiPurchaseOrders = (props) => {
  const toast = useRef(null);
  const formRef = useRef(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const [ediPurchaseOrders, setEdiPurchaseOrders] = useState([]);
  const [disableFields, setDisableFields] = useState(false);
  const [filterValues, setFilterValues] = useState();
  const [duplicateModalVisible, setDuplicateModalVisible] = useState(false);
  const [duplicatePOs, setDuplicatePOs] = useState([]);
  const [isFailedOrdersExist, setIsFailedOrdersExist] = useState(false);
  const [fetchLatestDuplicatePOs, setFetchLatestDuplicatePOs] = useState(0);

  function handleSetFetchLatestDuplicatePOsFromChild() {
    setFetchLatestDuplicatePOs(fetchLatestDuplicatePOs + 1);
  }

  const showStatusList = [
    { label: "All", value: showStatusOptions.All },
    { label: "Pending", value: showStatusOptions.Pending },
    { label: "Submitted", value: showStatusOptions.Submitted },
    { label: "Processed", value: showStatusOptions.Processed },
    { label: "Failed", value: showStatusOptions.Failed },
    { label: "Cancelled", value: showStatusOptions.Cancelled }
  ];

  useEffect(() => {
    fetchEdiPurchaseOrders(initialValue);
  }, []);

  useEffect(() => {
    fetchDuplicateEDIPOs();
    fetchHasFailedOrders();
  }, [fetchLatestDuplicatePOs]);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const fetchDuplicateEDIPOs = async () => {
    try {
      let result = await getDuplicateEdiPurchaseOrders();
      setDuplicatePOs(result);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 4000
      });
    }
  };

  const fetchHasFailedOrders = async () => {
    let response;

    try {
      response = await getFailedOrdersExist();
      setIsFailedOrdersExist(response.result === true ? true : false);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Failed Orders Exist Request Failed",
        detail: error.message,
        life: 3000
      });
    }

    if (response?.statusCode && response?.statusCode !== 200) {
      toast.current.show({
        severity: "error",
        summary: "Failed Orders Exist Request Failed",
        detail: response.result,
        life: 3000
      });
    }
  };

  const fetchEdiPurchaseOrders = async (formbody) => {
    try {
      setShowSpinner(true);

      formbody = {
        purchaseOrderNumber: formbody?.purchaseOrderNumber,
        vendorSequenceNumber: formbody?.vendorSequenceNumber
          ? formbody?.vendorSequenceNumber
          : null,
        showStatus: formbody?.showStatus ? formbody?.showStatus : null,
        deliveryStartDate: formbody?.deliveryStartDate
          ? formatDate(formbody?.deliveryStartDate)
          : null,
        deliveryEndDate: formbody?.deliveryEndDate
          ? formatDate(formbody?.deliveryEndDate)
          : null
      };

      let result = "";
      result = await getEdiPurchaseOrders(formbody);

      setFilterValues(formbody);

      setEdiPurchaseOrders(result);
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      setEdiPurchaseOrders([]);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 4000
      });
    }
  };

  const deliveryDateFormat = (data) => {
    return formatDateTime(data.deliveryDate);
  };

  function formatDateTime(isoString) {
    const date = parseISO(isoString);
    return format(date, "yyyy-MM-dd");
  }

  const processingStatusFormat = (data) => {
    let severityValue = "";

    switch (data.processingStatus) {
      case showStatusOptions.Pending:
        severityValue = "secondary";
        break;
      case showStatusOptions.Submitted:
        severityValue = "info";
        break;
      case showStatusOptions.Processed:
        severityValue = "success";
        break;
      case showStatusOptions.Failed:
        severityValue = "danger";
        break;
      case showStatusOptions.Cancelled:
        severityValue = "warning";
        break;
      default:
        severityValue = "";
    }

    return (
      <div>
        <Tag
          severity={severityValue}
          value={showStatusKeyValueOptions[data.processingStatus]}
          rounded
          style={
            data.processingStatus === showStatusOptions.Cancelled
              ? { background: "black", color: "white" }
              : null
          }
        ></Tag>
      </div>
    );
  };

  const duplicateModalHeaderElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Duplicate PO#</span>
    </div>
  );

  const onHideDuplicateModal = () => {
    setDuplicateModalVisible(false);
  };

  const exportExcelHandler = async () => {
    let filtersObj = formRef.current.values;

    filtersObj = {
      purchaseOrderNumber: filtersObj?.purchaseOrderNumber
        ? filtersObj?.purchaseOrderNumber
        : null,
      vendorSequenceNumber: filtersObj?.vendorSequenceNumber
        ? filtersObj?.vendorSequenceNumber
        : null,
      showStatus: filtersObj?.showStatus ? filtersObj?.showStatus : null,
      deliveryStartDate: filtersObj?.deliveryStartDate
        ? formatDate(filtersObj?.deliveryStartDate)
        : null,
      deliveryEndDate: filtersObj?.deliveryEndDate
        ? formatDate(filtersObj?.deliveryEndDate)
        : null
    };

    try {
      setShowSpinner(true);
      let result = await getEdiPurchaseOrders(filtersObj);

      const flattenedData = result
        .map((item) => {
          return {
            PONumber: item.poNumber,
            DeliveryDate: item.deliveryDate.substr(0, 10),
            VendorSequenceNumber: item.vendorSequenceNumber,
            DistributionCenterNumber: item.distributionCenterNumber,
            ActualFulfillmentRegion: item.actualFulfillmentRegion,
            ProcessingStatus: showStatusKeyValueOptions[item.processingStatus],
            BuyingPartyName: item.buyingPartyName,
            SupplierName: item.supplierName,
            DuplicatePO: item.isDuplicatePO,
            EdiFileCreatedDateUTC: item.ediFileCreatedDateUTC.substr(0, 10),
            EdiFileName: item.ediFileName
          };
        })
        .flat();

      if (flattenedData.length === 0) {
        setShowSpinner(false);

        toast.current.show({
          severity: "error",
          summary: "Export Error",
          detail: "No Records to export",
          life: 3000
        });

        return;
      }

      exportExcel(flattenedData);
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 4000
      });
    }
  };

  const exportExcel = (exportEdiPOContent) => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(exportEdiPOContent);
      const workbook = {
        Sheets: { SwitchboardOrders: worksheet },
        SheetNames: ["SwitchboardOrders"]
      };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array"
      });

      saveAsExcelFile(excelBuffer, "Switchboard-Orders");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <>
      {showSpinner && <Spinner />}

      <Dialog
        visible={duplicateModalVisible}
        modal
        header={duplicateModalHeaderElement}
        style={{ width: "40vw" }}
        breakpoints={{ "1024px": "75vw", "641px": "100vw" }}
        onHide={onHideDuplicateModal}
        maximizable
      >
        <DataTable
          value={duplicatePOs.duplicatePurchaseOrders}
          // tableStyle={{ minWidth: "50rem" }}
          paginatorTemplate={constants.paginatorTemplate}
          paginator={constants.paginator}
          rows={constants.defaultRows}
          rowsPerPageOptions={constants.rowsPerPageOptions}
          emptyMessage={constants.emptyMessage}
          currentPageReportTemplate={constants.currentPageReportTemplate}
          dataKey="ediPurchaseOrderID"
        >
          {ediDuplicateColumns?.map((col, i) => (
            <Column
              key={col?.field}
              field={col?.field}
              header={col?.header}
              sortable={col?.sort}
              style={({ minWidth: col?.minWidth }, { height: "2.5rem" })}
              headerStyle={{ minWidth: col.minWidth }}
              body={
                col.field.toLocaleLowerCase() === "deliverydate"
                  ? deliveryDateFormat
                  : col.field.toLocaleLowerCase() === "processingstatus"
                  ? processingStatusFormat
                  : col.value
              }
            />
          ))}
        </DataTable>
      </Dialog>

      <Formik
        initialValues={initialValue}
        onSubmit={(values) => {
          setShowSpinner(true);
          handleSetFetchLatestDuplicatePOsFromChild();
          fetchEdiPurchaseOrders(values);
        }}
        innerRef={formRef}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit} ref={formRef}>
            <div className="grid">
              <div className="col-12 md:col-2">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="showStatus">
                    <span className={styles.formLabel}>Processing Status</span>
                  </label>
                </div>
                <Dropdown
                  id="showStatus"
                  filter
                  placeholder="Select a Processing Status"
                  value={values?.showStatus}
                  options={showStatusList}
                  onChange={(e) => {
                    setFieldValue("showStatus", e.value);
                  }}
                  className={`${styles.dropdown} ${styles.formControl}`}
                />
              </div>

              <div className="col-12 md:col-2">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="purchaseOrderNumber">
                    <span className={styles.formLabel}>PO Number</span>
                  </label>
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-search"></i>
                  </span>
                  <InputText
                    id="purchaseOrderNumber"
                    placeholder="PO Number"
                    value={values?.purchaseOrderNumber}
                    onChange={handleChange}
                    className={`${styles.inputField} ${styles.formControl} ${styles.noBorder}`}
                  />
                </div>
              </div>

              <div className="col-12 md:col-4">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="vendorSequenceNumber">
                    <span className={styles.formLabel}>
                      Vendor Sequence Number
                    </span>
                  </label>
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-search"></i>
                  </span>
                  <InputText
                    id="vendorSequenceNumber"
                    placeholder="Vendor Sequence Number"
                    value={values?.vendorSequenceNumber}
                    onChange={handleChange}
                    className={`${styles.inputField} ${styles.formControl} ${styles.noBorder}`}
                  />
                </div>
              </div>

              <div className="col-12 md:col-2">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="deliveryStartDate">
                    <span className={styles.formLabel}>
                      PO Created Date Range
                    </span>
                  </label>
                </div>
                <Calendar
                  id="deliveryStartDate"
                  value={values?.deliveryStartDate}
                  onChange={handleChange}
                  disabled={disableFields}
                  placeholder="Start Date"
                  maxDate={formRef?.current?.values?.deliveryEndDate}
                />
              </div>
              <div className="col-12 md:col-2">
                <div className={styles.formLabelContainer}></div>
                <Calendar
                  id="deliveryEndDate"
                  value={values?.deliveryEndDate}
                  onChange={handleChange}
                  disabled={disableFields}
                  placeholder="End Date"
                  minDate={formRef?.current?.values?.deliveryStartDate}
                />
              </div>
            </div>

            <div
              className="flex justify-content-end flex-wrap gap-3"
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              <div className="flex align-items-center justify-content-center">
                <div className={styles.formLabelContainer}></div>
                <Button
                  label="Search"
                  type="submit"
                  size="medium"
                  className={styles.primaryBtn}
                />
              </div>
              <div className="flex align-items-center justify-content-center">
                <div className={styles.formLabelContainer}></div>
                <Button
                  label="Reset"
                  type="button"
                  size="medium"
                  onClick={(e) => {
                    formRef.current.setValues({
                      purchaseOrderNumber: "",
                      vendorSequenceNumber: "",
                      showStatus: showStatusOptions.Pending
                    });

                    fetchEdiPurchaseOrders({
                      purchaseOrderNumber: "",
                      vendorSequenceNumber: "",
                      showStatus: showStatusOptions.Pending
                    });

                    handleSetFetchLatestDuplicatePOsFromChild();

                    setDisableFields(false);
                  }}
                  className={styles.resetBtn}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div
        className="flex justify-content-between flex-wrap"
        style={{ marginBottom: "0.2rem" }}
      >
        <div className="flex align-items-center justify-content-center gap-2">
          <Button
            className="flex align-items-center justify-content-center"
            type="button"
            icon="pi pi-file-excel"
            // rounded
            // data-pr-tooltip="XLS"
            tooltip="Export to Excel"
            onClick={exportExcelHandler}
            // label="Export to Excel"
            style={{ width: "2.5rem", height: "2.5rem" }}
          />
          <div style={{ color: "red", fontSize: "0.8rem" }}>
            {isFailedOrdersExist
              ? "Failed orders detected. Filter by 'Processing Status' to view and Revert!"
              : ""}
          </div>
        </div>

        <Button
          className="flex align-items-center justify-content-center"
          icon="pi pi-eye"
          label={
            duplicatePOs.pendingDuplicatePOCount === 0
              ? "No Duplicate POs Exist"
              : "Click here to view " +
                duplicatePOs.pendingDuplicatePOCount +
                " Duplicate POs"
          }
          text
          style={{ color: "red", marginBottom: "0.5rem" }}
          onClick={() => setDuplicateModalVisible(true)}
        />
      </div>

      <Table
        content={ediPurchaseOrders}
        filterValuesData={filterValues}
        columnsData={ediFilePurchaseOrderColumns}
        rowExpansionColumnsData={rowExpansionEdiLineItemsColumns}
        rows={constants.defaultRows}
        rowsPerPageOptions={constants.rowsPerPageOptions}
        paginator={constants.paginator}
        scrollable={constants.scrollable}
        emptyMessage={constants.emptyMessage}
        tableStyle={constants.tableStyle}
        currentPageReportTemplate={constants.currentPageReportTemplate}
        paginatorTemplate={constants.paginatorTemplate}
        isCopyEnable={false}
        styles={styles}
        currentFilter={initialValue.showStatus}
        sendFetchLatestDuplicatePOsToParent={
          handleSetFetchLatestDuplicatePOsFromChild
        }
      />

      <Toast ref={toast} position="center" />
    </>
  );
};

export default EdiPurchaseOrders;
