import {api} from "../api/api";
import JSZip from 'jszip';

export const logSummaryApi = async (params) => {
  try {
    const config = {
      responseType: 'arraybuffer',
      transformRequest: [(data) => data],
      params, // params are included directly here
    };

    const response = await api.get('/api/LogSummary/LargeData', config);

    if (!(response.data instanceof ArrayBuffer) || response.data.byteLength === 0) {
      throw new Error('Received an empty or invalid ZIP file.');
    }

    const zip = new JSZip();
    const unzippedData = await zip.loadAsync(response.data);

    const extractedFiles = {};
    for (const filename of Object.keys(unzippedData.files)) {
      const fileContent = await unzippedData.files[filename].async('string');
      extractedFiles[filename] = JSON.parse(fileContent);
    }

    return extractedFiles['response.json'];
  } catch (error) {
    let errorMessage = 'Failed to process data';
    if (error.response && error.response.data) {
      try {
        const errorText = new TextDecoder().decode(error.response.data);
        const errorData = JSON.parse(errorText);
        errorMessage = errorData?.title || `Request failed with status ${error.response.status}`;
      } catch (parseError) {
        console.error('Error parsing error response:', parseError);
        errorMessage = `Request failed with status ${error.response.status}`;
      }
    } else if (error.message) {
      errorMessage = error.message;
    } else {
      console.error('Unexpected error:', error);
    }
    throw new Error(errorMessage);
  }
};


export const reProcessApi = async (fileIds) =>{
  try {
    const response = await api.put(`/api/Orders/Re-process`, fileIds);
    return response.data?.result?.result || [];
  } catch (error) {
    if(error?.code?.trim() == "ERR_NETWORK"){
      throw new Error("It looks like we can't reach the server right now. Please try refreshing the page or check after sometime.");
    }
    else{
      throw new Error(error?.response?.data?.title);
    }
  }
}

export const deleteDataItem = async (data) =>{
  try{
    const response = await api.delete(`/api/Orders/${data?.batchId}`);
    return response.data?.result?.result || [];
  }
  catch(error){
    if(error?.code?.trim() == "ERR_NETWORK"){
      throw new Error("It looks like we can't reach the server right now. Please try refreshing the page or check after sometime.");
    }
    else{
      throw new Error(error?.response?.data?.title);
    }
  }
}