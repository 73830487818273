import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { FaCircleInfo } from "react-icons/fa6";
import styles from "./User.module.scss";
import { useFormik, validateYupSchema } from "formik";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { useDispatch, useSelector } from "react-redux";
import * as YUP from "yup";
import { sortData } from "../../constants/dataSort";
import { addNewUser, editUserList } from "../../services/userManagement";
import { Toast } from "primereact/toast";
import Spinner from "../../components/spinner/spinner";
import { appInsights } from "../../AppInsights";

const initialValue = {
  firstName: "",
  lastName: "",
  email: "",
  roleId: null,
  region: null
};

const UserDialog = ({
  visible,
  onHide,
  flag,
  data,
  fetchUserList,
  fetchUser
}) => {
  appInsights.trackPageView({ name: "User Management Dialog visited" });
  const dispatch = useDispatch();
  const toast = useRef(null);
  const formRef = useRef(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const { getFunctionMasterData } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const masterDataForFunction = useSelector((state) => {
    return state.orderReducer.masterData;
  });
  useEffect(() => {
    getFunctionMasterData();
  }, []);

  const role = Array.isArray(masterDataForFunction?.roles)
    ? ("roleId", masterDataForFunction?.roles)?.map((role) => ({
        label: role?.name,
        value: role?.roleId
      }))
    : [];
  const regionsList = Array.isArray(masterDataForFunction?.regions)
    ? sortData("regionNameAbbr", masterDataForFunction?.regions).map(
        (region) => ({
          label: region?.regionNameAbbr,
          value: region?.regionId
        })
      )
    : [];

  const commonValidation = YUP.mixed().nullable();
  const validationSchema = YUP.object().shape({
    firstName: YUP.string()
      .required("First Name is required")
      .min(3, "First name must be at least 3 characters")
      .max(50, "First name must be at most 50 characters")
      .test(
        "no-only-spaces",
        "Field can't be empty",
        (value) => value && value?.trim()?.length > 0
      )
      .matches(/^[^0-9]*$/, "Field cannot contain numbers"),
    lastName: YUP.string()
      .required("Last Name is required")
      .min(3, "Last name must be at least 3 characters")
      .max(50, "Last name must be at most 50 characters")
      .test(
        "no-only-spaces",
        "Field can't be empty",
        (value) => value && value?.trim()?.length > 0
      )
      .matches(/^[^0-9]*$/, "Field cannot contain numbers"),
    email: YUP.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@taylorfarms\.com$/,
        "Email must be a valid @taylorfarms.com address"
      ),
    roleId: commonValidation.required("Role is required"),
    region: commonValidation.required("Region is required")
  });
  const formik = useFormik({
    initialValues: data || initialValue,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (flag) {
        addUserHandler(values);
      } else {
        editUserHandle(values);
      }
    }
  });
  useEffect(() => {
    if (data && masterDataForFunction) {
      prePopulateData();
    }
  }, [data, masterDataForFunction]);

  const prePopulateData = () => {
    if (data) {
      formik.setFieldValue("firstName", data?.firstName || "");
      formik.setFieldValue("lastName", data?.lastName || "");
      formik.setFieldValue("email", data?.emailAdrress || "");
      const roleOption = role?.find(
        (option) => option.label === data?.roleName
      );
      formik.setFieldValue("roleId", roleOption?.value || null);
      const regionOption = regionsList.find(
        (option) => option.label === data?.regionNameAbbr
      );
      formik.setFieldValue("region", regionOption?.value || null);
    }
  };

  const addUserHandler = async (formbody) => {
    try {
      setShowSpinner(true);
      const requestBody = {
        firstName: formbody.firstName,
        lastName: formbody.lastName,
        emailAdrress: formbody.email,
        roleId: parseInt(formbody.roleId, 10),
        regionId: parseInt(formbody.region, 10)
      };
      const result = await addNewUser(requestBody);
      if (result) {
        setShowSpinner(false);
        fetchUserList();
        formik.resetForm();
        setTimeout(() => {
          onHide();
        }, 2000);
        toast?.current?.show(
          {
            severity: "success",
            summary: "User Added Successfully",
            life: 4000
          },
          200
        );
      }
    } catch (error) {
      setShowSpinner(false);
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: error?.message,
        life: 4000
      });
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Error while adding new user in user managemnt"
        },
        measurements: {
          severityLevel: 2
        }
      });
    }
  };
  const editUserHandle = async (formbody) => {
    try {
      setShowSpinner(true);
      const requestBody = {
        userId: data?.userId,
        firstName: formbody.firstName,
        lastName: formbody.lastName,
        emailAdrress: formbody.email,
        roleId: parseInt(formbody.roleId, 10),
        regionId: parseInt(formbody.region, 10)
      };
      const result = await editUserList(requestBody);
      if (result) {
        setShowSpinner(false);
        fetchUser();
        formik.resetForm({ values: initialValue });
        setTimeout(() => {
          onHide();
        }, 2000);
        toast?.current?.show(
          {
            severity: "success",
            summary: "User Edited Successfully",
            life: 2000
          },
          200
        );
      }
    } catch (error) {
      setShowSpinner(false);
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: error?.message,
        life: 4000
      });
    }
  };
  return (
    <div>
      {showSpinner && <Spinner />}
      <Dialog
        header={flag ? "Add User" : "Edit User"}
        visible={visible}
        onHide={onHide}
        style={{ width: "65vw" }}
        className={styles.userDialog}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="mb-2">
                <label htmlFor="firstName">First Name</label>
              </div>
              <div>
                <InputText
                  id="firstName"
                  className="w-full"
                  placeholder="Enter First Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                />
              </div>
              <div>
                {formik.errors.firstName && formik.touched.firstName && (
                  <span className={styles.errorMsg}>
                    {formik.errors.firstName}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="mb-2">
                <label htmlFor="lastName">Last Name</label>
              </div>
              <div>
                <InputText
                  id="lastName"
                  className="w-full"
                  placeholder="Enter Last Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                />
              </div>
              <div>
                {formik.errors.lastName && formik.touched.lastName && (
                  <span className={styles.errorMsg}>
                    {formik.errors.lastName}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="mb-2">
                <label htmlFor="email">Email Address</label>
              </div>
              <div>
                <InputText
                  id="email"
                  className="w-full"
                  placeholder="example@taylorfarms.com"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              </div>
              <div>
                {formik.errors.email && formik.touched.email && (
                  <span className={styles.errorMsg}>{formik.errors.email}</span>
                )}
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="mb-2">
                <label htmlFor="role">Role</label>
              </div>
              {!flag && role.length === 0 ? (
                <Dropdown loading className="w-full py-1" placeholder="Role" />
              ) : (
                <Dropdown
                  placeholder="Select"
                  className="w-full py-1"
                  options={role}
                  value={formik.values.roleId}
                  onChange={(e) => formik.setFieldValue("roleId", e.value)}
                />
              )}
              <div>
                {formik.errors.roleId && formik.touched.roleId && (
                  <span className={styles.errorMsg}>
                    {formik.errors.roleId}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="mb-2">
                <label htmlFor="region">Region</label>
              </div>
              {!flag && regionsList.length === 0 ? (
                <Dropdown
                  loading
                  className="w-full py-1"
                  placeholder="Region"
                />
              ) : (
                <Dropdown
                  placeholder="Select"
                  className="w-full py-1"
                  options={regionsList}
                  filter
                  value={formik.values.region}
                  onChange={(e) => formik.setFieldValue("region", e.value)}
                />
              )}
              <div>
                {formik.errors.region && formik.touched.region && (
                  <span className={styles.errorMsg}>
                    {formik.errors.region}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12">
              <div>
                <p className="flex align-content-center">
                  <FaCircleInfo className="mt-1" />
                  {flag ? (
                    <span className="pl-1">
                      User will receive a confirmation email once their account
                      is successfully created.
                    </span>
                  ) : (
                    <span className="pl-1">
                      A confirmation email will be sent to the user when their
                      account is updated
                    </span>
                  )}
                </p>
              </div>
              <Button type="submit" className={styles.submitUserHandler}>
                {flag ? <span>Save User</span> : <span>Update User</span>}
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
      <Toast ref={toast} position="center" />
    </div>
  );
};

export default UserDialog;
