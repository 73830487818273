export const customerItemGroupList = [
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "ADUSA",
    customerName: "ADUSA-100000"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "CPAK",
    customerName: "CPAK-100490"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "CHR",
    customerName: "CHR-100600"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "CircleKChr",
    customerName: "CircleKChr-100721"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "BLS",
    customerName: "BLS-101300"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "EBY",
    customerName: "EBY-101480"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "C&S",
    customerName: "C&S-101800"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "CASEYSDSD",
    customerName: "CASEYSDSD-102130"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "MCD",
    customerName: "MCD-103150"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "MEIJER",
    customerName: "MEIJER-1040000"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "KrogWH",
    customerName: "KrogWH-104200"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "WM",
    customerName: "WM-105600"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "SPV",
    customerName: "SPV-1060000"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "PapaJhnTN",
    customerName: "PapaJhnTN-106239"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "RFS",
    customerName: "RFS-106600"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "Sprouts",
    customerName: "Sprouts-107030"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "SYSCOCA",
    customerName: "SYSCOCA-107100"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "SCH",
    customerName: "SCH-1080000"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "RFS",
    customerName: "RFS-108400"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "MCD",
    customerName: "MCD-108700"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "US Foods",
    customerName: "US Foods-109390"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "WM PTI",
    customerName: "WM PTI-109900"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "TJ",
    customerName: "TJ-1100000"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "SYSCO",
    customerName: "SYSCO-110100"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "WM PTI",
    customerName: "WM PTI-110200"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "USFS",
    customerName: "USFS-112400"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "Wakefern U",
    customerName: "Wakefern U-112900"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "MCE",
    customerName: "MCE-1130000"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "Wegmans",
    customerName: "Wegmans-113300"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "CHR-WHSE",
    customerName: "CHR-WHSE-1160000"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "MCD",
    customerName: "MCD-117800"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "SAFE",
    customerName: "SAFE-118600"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "MCD",
    customerName: "MCD-132000"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "LIDL",
    customerName: "LIDL-135700"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "ADUSA",
    customerName: "ADUSA-140000"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "S&S frtwn",
    customerName: "S&S frtwn-140000"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "Wegmans",
    customerName: "Wegmans-141000"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "SAFE",
    customerName: "SAFE-141200"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "ALB",
    customerName: "ALB-142600"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "KrogTFUPC",
    customerName: "KrogTFUPC-1670000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "SBPronzo",
    customerName: "SBPronzo-1680000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "SAL",
    customerName: "SAL-1720000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "CH Robinso",
    customerName: "CH Robinso-1740000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "CHR Ocado",
    customerName: "CHR Ocado-1740000"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "TARGET",
    customerName: "TARGET-230000"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "KROGTNUPC",
    customerName: "KROGTNUPC-300000"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "HomeChef",
    customerName: "HomeChef-310000"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "HomeChefKy",
    customerName: "HomeChefKy-310000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "SBPronzo",
    customerName: "SBPronzo-3100000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "711-WD",
    customerName: "711-WD-3300000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "ALBJEWELWH",
    customerName: "ALBJEWELWH-3400000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "AMZ -KIT2",
    customerName: "AMZ -KIT2-3700000"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "KROGTNOCAD",
    customerName: "KROGTNOCAD-375000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "AMZ -KIT2",
    customerName: "AMZ -KIT2-3800000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "HOMECHEF",
    customerName: "HOMECHEF-4500000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "QCD",
    customerName: "QCD-4600000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "GE",
    customerName: "GE-4700000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "RNDYS",
    customerName: "RNDYS-4800000"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "SBX",
    customerName: "SBX-500000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "TGT",
    customerName: "TGT-5300000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "TJ",
    customerName: "TJ-5400000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "MCLANE",
    customerName: "MCLANE-5700000"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "SBX",
    customerName: "SBX-580000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "AMZ HUD",
    customerName: "AMZ HUD-5800000"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "Sprouts",
    customerName: "Sprouts-700000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "LIP",
    customerName: "LIP-1400000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "ALBSFWYUT",
    customerName: "ALBSFWYUT-200000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "SFWYDENVER",
    customerName: "SFWYDENVER-204000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "SHELFENG",
    customerName: "SHELFENG-210000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "SPROUT_ITR",
    customerName: "SPROUT_ITR-210000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "SPROUTS",
    customerName: "SPROUTS-210000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "COREMARK",
    customerName: "COREMARK-320000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "BASH",
    customerName: "BASH-400000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "KROG",
    customerName: "KROG-503154"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "KROG",
    customerName: "KROG-504192"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "KROG",
    customerName: "KROG-509000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "KROG",
    customerName: "KROG-540000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "HOMECHEF",
    customerName: "HOMECHEF-550000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "KROG",
    customerName: "KROG-570000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "WM",
    customerName: "WM-600000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "WM WH",
    customerName: "WM WH-601000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "SHELFENG",
    customerName: "SHELFENG-620000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "Canteen",
    customerName: "Canteen-700001"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "Canteen",
    customerName: "Canteen-700002"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "Canteen",
    customerName: "Canteen-703000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "SBUX",
    customerName: "SBUX-800000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-900000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-900002"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "CANCER",
    customerName: "CANCER-900004"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-900010"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-900012"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "GELSONS",
    customerName: "GELSONS-900014"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-900018"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-900020"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-900022"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "QT",
    customerName: "QT-900026"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "QT",
    customerName: "QT-900028"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-900030"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-900032"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "SYSCO",
    customerName: "SYSCO-910000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "K&G",
    customerName: "K&G-920000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "MCLANE",
    customerName: "MCLANE-920003"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "CENTRAL",
    customerName: "CENTRAL-920007"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "MARKON",
    customerName: "MARKON-930000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "OCADO",
    customerName: "OCADO-940000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-950000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-950002"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-950004"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-950006"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "ALBSFWYPX",
    customerName: "ALBSFWYPX-950008"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "BASH",
    customerName: "BASH-950008"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "Canteen",
    customerName: "Canteen-950008"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "COREMARK",
    customerName: "COREMARK-950008"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-950008"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "HOMECHEF",
    customerName: "HOMECHEF-950008"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "KROG",
    customerName: "KROG-950008"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "MARKON",
    customerName: "MARKON-950008"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "SBUX",
    customerName: "SBUX-950008"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "SFWYDENVER",
    customerName: "SFWYDENVER-950008"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "WM",
    customerName: "WM-950008"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "WM WH",
    customerName: "WM WH-950008"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-950010"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-950036"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-950038"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-960042"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "KROG",
    customerName: "KROG-960044"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "QT",
    customerName: "QT-970000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FACTOR75",
    customerName: "FACTOR75-970003"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "CASA",
    customerName: "CASA-970005"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "FDSV",
    customerName: "FDSV-970007"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "ALBSFWYPX",
    customerName: "ALBSFWYPX-100000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "CIC ALB SW",
    customerName: "CIC ALB SW-100000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "AFS",
    customerName: "AFS-300000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "SHELFENG",
    customerName: "SHELFENG-310000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "KROG",
    customerName: "KROG-500000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "KROG",
    customerName: "KROG-580000"
  },
  {
    regionId: 23,
    regionNameAbbr: "TPJ",
    customerItemGroupId: "Canteen",
    customerName: "Canteen-700000"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "WALMART",
    customerName: "WALMART-10004"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "CentEatChr",
    customerName: "CentEatChr-100462"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "WMCP",
    customerName: "WMCP-100690"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "McL-Direct",
    customerName: "McL-Direct-100726"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "WALMART",
    customerName: "WALMART-10127"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "ALBAcmeDC",
    customerName: "ALBAcmeDC-10187"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "ALBShawsDC",
    customerName: "ALBShawsDC-10187"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "Target",
    customerName: "Target-10200"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "SMRET",
    customerName: "SMRET-110100"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "SMTU",
    customerName: "SMTU-110100"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "Ahold CH",
    customerName: "Ahold CH-11020"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "Ahold FT",
    customerName: "Ahold FT-11020"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "Ahold JP",
    customerName: "Ahold JP-11020"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "CHRVMI",
    customerName: "CHRVMI-11040"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "Wegmans",
    customerName: "Wegmans-11070"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "Kroger",
    customerName: "Kroger-112100"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "TJOE",
    customerName: "TJOE-113000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "Sprouts",
    customerName: "Sprouts-129000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "Tar-PAC",
    customerName: "Tar-PAC-169000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "Tar-PAC",
    customerName: "Tar-PAC-170100"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "SBLunch",
    customerName: "SBLunch-192000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "SWY-Combo",
    customerName: "SWY-Combo-200000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "SWY-TFP",
    customerName: "SWY-TFP-200000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "ALB-IMW",
    customerName: "ALB-IMW-201000"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "CPAK",
    customerName: "CPAK-20116"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "ALBDSD",
    customerName: "ALBDSD-30000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "Costco",
    customerName: "Costco-300000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "SBUX",
    customerName: "SBUX-3100000"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "400000",
    customerName: "400000-400000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "RALEYS",
    customerName: "RALEYS-400000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "AMZ",
    customerName: "AMZ-470000"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "SBUX",
    customerName: "SBUX-500100"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "Sprouts",
    customerName: "Sprouts-520000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "Kroger",
    customerName: "Kroger-560000"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "AHOLD",
    customerName: "AHOLD-70000"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "UDF",
    customerName: "UDF-800000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "WM-CoPack",
    customerName: "WM-CoPack-800000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "WM-Pacific",
    customerName: "WM-Pacific-800000"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "WAWA",
    customerName: "WAWA-90000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "WM-Pacific",
    customerName: "WM-Pacific-900500"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "TJ",
    customerName: "TJ-1210000"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "RFS",
    customerName: "RFS-103620"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "RFS",
    customerName: "RFS-106700"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "RFS",
    customerName: "RFS-106850"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "SYSCO",
    customerName: "SYSCO-107800"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "SYSCO",
    customerName: "SYSCO-107820"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "WM PTI",
    customerName: "WM PTI-109650"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "711CGO",
    customerName: "711CGO-1110000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "711DET",
    customerName: "711DET-1110000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "711DET",
    customerName: "711DET-1120000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "7ELV",
    customerName: "7ELV-1150000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "AWG",
    customerName: "AWG-1200000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "TF",
    customerName: "TF-1260000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "TF",
    customerName: "TF-1490000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "TARULF",
    customerName: "TARULF-1510000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "TF",
    customerName: "TF-1510000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "RSG",
    customerName: "RSG-1530000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "TF",
    customerName: "TF-1590000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "TF",
    customerName: "TF-1620000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "TF",
    customerName: "TF-1680000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "HL",
    customerName: "HL-1730000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "AMC",
    customerName: "AMC-1750000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "WFM_RB",
    customerName: "WFM_RB-4200000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "WFM_RB",
    customerName: "WFM_RB-4300000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "GE",
    customerName: "GE-4710000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "DAV",
    customerName: "DAV-4900000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "SCG",
    customerName: "SCG-5100000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "SCG",
    customerName: "SCG-5200000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "SCG",
    customerName: "SCG-5500000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "TJ",
    customerName: "TJ-5600000"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "TF",
    customerName: "TF-5900000"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "TFMD",
    customerName: "TFMD-10112"
  },
  {
    regionId: 4,
    regionNameAbbr: "TFNJ",
    customerItemGroupId: "TJSDeli",
    customerName: "TJSDeli-10154"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "IMF",
    customerName: "IMF-103000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "AMZ",
    customerName: "AMZ-114110"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "WF",
    customerName: "WF-114110"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "AMZ",
    customerName: "AMZ-114120"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "WF",
    customerName: "WF-114120"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "AMZ",
    customerName: "AMZ-114130"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "WF",
    customerName: "WF-114130"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "AMZ",
    customerName: "AMZ-114140"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "WF",
    customerName: "WF-114140"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "RALEYS",
    customerName: "RALEYS-122220"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "07-11-2024",
    customerName: "07-11-2024-124000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "Kroger",
    customerName: "Kroger-210000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "CSTDEL",
    customerName: "CSTDEL-300000"
  },
  {
    regionId: 38,
    regionNameAbbr: "tfpi",
    customerItemGroupId: "TAYSUN",
    customerName: "TAYSUN-900000"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "SAFE",
    customerName: "SAFE-NULL"
  },
  {
    regionId: 7,
    regionNameAbbr: "TFIL",
    customerItemGroupId: "TF",
    customerName: "TF-NULL"
  },
  {
    regionId: 6,
    regionNameAbbr: "TFMD",
    customerItemGroupId: "Wakefern U",
    customerName: "Wakefern U-NULL"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "MCLANE",
    customerName: "MCLANE-104920"
  },
  {
    regionId: 5,
    regionNameAbbr: "TFTN",
    customerItemGroupId: "MCLANE-VMI",
    customerName: "MCLANE-VMI-104920"
  }
];

export const itemXrefDataList = [
  {
    regionNameAbbr: "TFIL",
    customerName: "MEIJER-1040000",
    itemId: "123169",
    customerItemId: "5130062-006-001",
    customerItemGroupId: "MEIJER",
    itemDescription: "FIESTA WITH CHICKEN 6/6.35 OZ"
  },
  {
    regionNameAbbr: "TFIL",
    customerName: "MEIJER-1040000",
    itemId: "123170",
    customerItemId: "5129506-006-001",
    customerItemGroupId: "MEIJER",
    itemDescription: "BLACK BEAN TACO SALAD 6/6.55 OZ"
  },
  {
    regionNameAbbr: "TFIL",
    customerName: "MEIJER-1040000",
    itemId: "123850",
    customerItemId: "5178877-002-001",
    customerItemGroupId: "MEIJER",
    itemDescription: "RED CHICKEN ENCHILADA FAMILY KIT"
  },
  {
    regionNameAbbr: "TFIL",
    customerName: "MEIJER-1040000",
    itemId: "123851",
    customerItemId: "5178875-002-001",
    customerItemGroupId: "MEIJER",
    itemDescription: "CHICKEN PARMESAN W/ SPAGHETTI FAMILY KIT"
  },
  {
    regionNameAbbr: "TFIL",
    customerName: "MEIJER-1040000",
    itemId: "123852",
    customerItemId: "5202113-004-001",
    customerItemGroupId: "MEIJER",
    itemDescription: "LATIN QUINOA BOWL WITH CHICKEN KIT"
  },
  {
    regionNameAbbr: "TFIL",
    customerName: "MEIJER-1040000",
    itemId: "123853",
    customerItemId: "5202112-004-001",
    customerItemGroupId: "MEIJER",
    itemDescription: "CHICKEN TERIYAKI KIT"
  },
  {
    regionNameAbbr: "TFIL",
    customerName: "MEIJER-1040000",
    itemId: "123854",
    customerItemId: "5202114-004-001",
    customerItemGroupId: "MEIJER",
    itemDescription: "SPINACH RAVIOLI PRIMAVERA KIT"
  },
  {
    regionNameAbbr: "TFIL",
    customerName: "MEIJER-1040000",
    itemId: "125617",
    customerItemId: "5350107-006-001",
    customerItemGroupId: "MEIJER",
    itemDescription: "MEIJER CREAMY ITALIAN TOSS UP SALAD 6/6.57 OZ"
  },
  {
    regionNameAbbr: "TFIL",
    customerName: "MEIJER-1040000",
    itemId: "125617",
    customerItemId: "713733248624",
    customerItemGroupId: "MEIJER",
    itemDescription: "MEIJER CREAMY ITALIAN TOSS UP SALAD 6/6.57 OZ"
  }
];

export const shipToXrefDataList = [
  {
    regionNameAbbr: "TFMD",
    customerName: "ADUSA-100000",
    shipToId: "0804911852011",
    customerShipToId: "100001",
    customerItemGroupId: "ADUSA"
  },
  {
    regionNameAbbr: "TFMD",
    customerName: "ADUSA-100000",
    shipToId: "0804911855911",
    customerShipToId: "100004",
    customerItemGroupId: "ADUSA"
  },
  {
    regionNameAbbr: "TFMD",
    customerName: "ADUSA-100000",
    shipToId: "0804911857184",
    customerShipToId: "100002",
    customerItemGroupId: "ADUSA"
  },
  {
    regionNameAbbr: "TFMD",
    customerName: "ADUSA-100000",
    shipToId: "0804911855411",
    customerShipToId: "100003",
    customerItemGroupId: "ADUSA"
  },
  {
    regionNameAbbr: "TFNJ",
    customerName: "WALMART-10004",
    shipToId: "0078742031804",
    customerShipToId: "10220",
    customerItemGroupId: "WALMART"
  },
  {
    regionNameAbbr: "TFNJ",
    customerName: "WALMART-10004",
    shipToId: "0078742035406",
    customerShipToId: "10221",
    customerItemGroupId: "WALMART"
  },
  {
    regionNameAbbr: "TFTN",
    customerName: "CentEatChr-100462",
    shipToId: "",
    customerShipToId: "100472",
    customerItemGroupId: "CentEatChr"
  },
  {
    regionNameAbbr: "TFTN",
    customerName: "CentEatChr-100462",
    shipToId: "",
    customerShipToId: "100471",
    customerItemGroupId: "CentEatChr"
  },
  {
    regionNameAbbr: "TFTN",
    customerName: "CentEatChr-100462",
    shipToId: "",
    customerShipToId: "100471",
    customerItemGroupId: "CentEatChr"
  }
];
